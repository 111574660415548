import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Emoji from "../components/emoji"
import { StaticImage } from "gatsby-plugin-image"

const AboutPage = ({ location }) => {
  return (
    <Layout location={location}>
      <div></div>
      <div>
        <Seo title="About" />
        <StaticImage
          src="../images/profile_about.jpg"
          alt="Profile image"
          layout="fullWidth"
          placeholder="tracedSVG"
          backgroundColor="transparent"
          imgClassName="rounded-2xl"
          className="mx-0 sm:mx-16 lg:mx-32"
        />

        <div className="mt-16 sm:mt-24 mx-0 sm:mx-16 lg:mx-32">
          <h2 className="text-black opacity-90 text-2xl sm:text-2.5xl font-medium">
            About me
          </h2>
          <p className="prose md:prose-lg max-w-none mt-8">
            Hey, I'm Menghan Zhang, a multidisciplinary product designer who is
            passionate about solving problems for people. Currently, I am a UX
            designer at Google, designing Chrome OS for education, enterprises,
            and consumers. Previously I worked as a product designer at Zhihu
            and studied at Carnegie Mellon University.
            <br></br>
            <br></br>
            When it comes to design, I love how Victor Papanek defined it in his
            book Design for the Real World. I believe good design is about
            intentionality and positive impact.
            <br></br>
            <br></br>
            <blockquote>
              "Design is the conscious effort to impose meaningful order." —
              Victor Papanek
            </blockquote>
            <br></br>
            When I’m not designing, I like to:
            <ul>
              <li>
                <Emoji symbol="📖" label="book" />
                <span className="pl-4">Grab a book and binge read</span>
              </li>
              <li>
                <Emoji symbol="🧘🏻" label="yoga" />
                <span className="pl-4">Do yoga to practice mindfulness</span>
              </li>
              <li>
                <Emoji symbol="🎨" label="paint" />
                <span className="pl-4">
                  Play with illustrations and Blender 3D
                </span>
              </li>
              <li>
                <Emoji symbol="🍱" label="sushi" />
                <span className="pl-4">
                  Try new recipes and snap a picture before the first bite
                </span>
              </li>
              <li>
                <Emoji symbol="😅" label="smile" />
                <span className="pl-4">
                  Find new hobbies to obsess over, then discard shortly after
                </span>
              </li>
            </ul>
          </p>
        </div>
        <div className="flex flex-col mt-12">
          <a
            href="https://www.instagram.com/menghan.xyz/"
            rel="noreferrer"
            target="_blank"
          >
            <div className="grid grid-cols-2 sm:grid-cols-4 gap-5">
              <StaticImage
                src="../images/illustration_1.jpg"
                alt="illustration 1"
                className="rounded-tl-2xl"
              />
              <StaticImage
                src="../images/illustration_2.jpg"
                alt="illustration 2"
                className="rounded-tr-2xl sm:rounded-none"
              />
              <StaticImage
                src="../images/illustration_3.jpg"
                alt="illustration 3"
              />
              <StaticImage
                src="../images/illustration_4.jpg"
                alt="illustration 4"
                className="rounded-none sm:rounded-tr-2xl"
              />
              <StaticImage
                src="../images/illustration_5.jpg"
                alt="illustration 5"
                className="rounded-none sm:rounded-bl-2xl"
              />
              <StaticImage
                src="../images/illustration_6.jpg"
                alt="illustration 6"
              />
              <StaticImage
                src="../images/illustration_7.jpg"
                alt="illustration 7"
                className="rounded-bl-2xl sm:rounded-none"
              />
              <StaticImage
                src="../images/illustration_8.jpg"
                alt="illustration 8"
                className="rounded-br-2xl"
              />
            </div>
          </a>
          <small className="text-center mt-10 text-black opacity-50 text-base sm:text-lg">
            Some of my illustrations and 3D modeling practice. See more{" "}
            <a
              href="https://www.instagram.com/menghan.xyz/"
              title="ins"
              rel="noreferrer"
              target="_blank"
              className="underline"
            >
              here
            </a>
          </small>
        </div>
      </div>
    </Layout>
  )
}

export default AboutPage
